<script>
import { mapState } from 'vuex';
export default {
  name: 'MonitoringView',

  data() {
    return {
      tab: null,
      disciplineTab: null,
      typeCount: 0,
      seed: 54,
      items: [
        { key: 'dq', title: 'Element Data Quality', id: 'data-quality' },
        { key: 'bc', title: 'Baseline Type Coverage', id: 'baseline-coverage' },
      ],
      disciplines: [
        {
          key: 'hhbr_ar',
          name: 'HHBR',
          discipline: 'Architecture',
          types: 16,
          aptitudeAndWillingness: 4,
          elements: 1000,
        },
        {
          key: 'arup-bs',
          name: 'Arup',
          discipline: 'Services',
          types: 25,
          aptitudeAndWillingness: 18,
          elements: 3500,
        },
        {
          key: 'arup-se',
          name: 'Arup',
          discipline: 'Structure',
          types: 7,
          aptitudeAndWillingness: 0,
          elements: 750,
        },
      ],
      zones: 20,
    };
  },
  computed: {
    ...mapState([
      'projectId',
      'activeMonitoringSet',
      'activeMonitoringProject',
    ]),

    teams() {
      return this.activeMonitoringProject.disciplines;
    },

    tableValues() {
      const rowHeadings = [];
      const columnHeadings = {};

      const cells = [];
      for (let i = 0; i < this.typeCount; i++) {
        rowHeadings.push(`Type ${i + 1}`);
        cells.push([]);
        for (let j = 0; j < this.zones; j++) {
          const score = this.disciplines[this.disciplineTab]
            ? Math.random(this.seed) -
              Math.random(this.seed) *
                this.disciplines[this.disciplineTab].aptitudeAndWillingness
            : 0;
          const state = score < 0 ? false : !Math.round(score);
          columnHeadings[`Zone ${String.fromCharCode(65 + j)}`] = j;
          cells[i].push({
            selected: score < 0 ? false : !Math.round(score),
            instances: state && Math.round(Math.random(this.seed) * 1000),
          });
        }
      }

      return {
        rowHeadings,
        columnHeadings: Object.keys(columnHeadings),
        cells,
      };
    },
    aptitudeAndWillingness() {
      return this.disciplines[this.disciplineTab]
        ? this.disciplines[this.disciplineTab].aptitudeAndWillingness
        : null;
    },
  },
  mounted() {
    // set the active project in the monitoring collection in firebase and reset discipline tab
    this.$store.dispatch('setActiveMonitoringSet', {
      projectKey: this.projectId,
    });
  },
  created() {
    this.tab = this.items.map(i => i.id).indexOf(this.$route.meta.tab);
    this.disciplineTab = this.disciplines
      .map(i => i.key)
      .indexOf(this.$route.hash.replace('#', ''));
    this.typeCount = this.disciplines[0].types;
    void this.tableValues;
  },
  methods: {
    handleChange(e) {
      const newRoute = `monitoring-${this.items[e].id}`;

      if (this.$route.name !== newRoute) {
        this.$router.push({
          name: newRoute,
          params: { projectId: this.$route.params.projectId },
          hash: this.$route.hash,
        });
      }
    },
    handleDisciplineChange(e) {
      this.typeCount = this.disciplines[e].types;
      this.$router.replace({
        name: this.$route.name,
        hash: `#${this.disciplines[e].key}`,
        params: { ...this.$route.params },
      });
    },
    reSeed() {
      this.seed = Math.random(this.seed);
    },
    changeAandW(v) {
      this.disciplines[this.disciplineTab].aptitudeAndWillingness = v;
    },
  },
};
</script>

<template>
  <div style="padding-left: 30px">
    <v-tabs v-model="tab" slider-color="action" left @change="handleChange">
      <v-tab v-for="item in items" :key="item.key" left>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-spacer />

    <v-tabs
      v-model="disciplineTab"
      style="margin-top: 15px"
      slider-color="action"
      left
      @change="handleDisciplineChange"
    >
      <v-tab v-for="team in disciplines" :key="team.key" left>
        {{ team.name }} | {{ team.discipline }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" :transition="false">
      <v-tab-item v-for="item in items" :key="item.key" :transition="false">
        <v-card flat>
          <!-- <keep-alive> -->
          <transition name="fade" mode="out-in">
            <router-view
              :name="item.key"
              :table-values="tableValues"
              :aptitude-and-willingness="aptitudeAndWillingness"
              :elements="
                disciplineTab >= 0 && disciplines[disciplineTab].elements
              "
              :seed="seed"
              @reSeed="reSeed"
              @aptitudeAndWillingness="changeAandW"
            />
            <router-view />
          </transition>
          <!-- </keep-alive> -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
.v-tab {
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  margin-right: 15px;
}
</style>

<style>
.fade-enter-to,
.fade-leave {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-tab.v-tab--active {
  color: var(--v-active-base);
}
</style>
